/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

$breakpoints: (
  "xs": 576px,
  "sm": 576px,
  "md": 768px,
  "lg": 992px,
  "xl": 1200px,
) !default;

@mixin media-breakpoint-up($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin media-breakpoint-down($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@font-face {
  font-family: "Circular STD Bold";
  src: url("./assets/fonts/CircularStd-Bold.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Circular STD Medium";
  src: url("./assets/fonts/CircularStd-Medium.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Circular STD Book";
  src: url("./assets/fonts/CircularStd-Book.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

ion-title {
  font-size: 21px !important;
  color: #000000 !important;
  position: static !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

ion-title.custom {
  color: #e8be22 !important;
}

ion-title.toolbar {
  color: #ffffff !important;
}

a {
  color: #76b2cc;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.red {
  color: #d3001d;
}

.grey {
  color: gray;
}

.bold {
  font-weight: bold;
}

.stitre {
  margin-top: 1px;
  font-size: 12px;
  font-weight: bold;
  color: var(--ion-color-dark);
}

.message {
  color: var(--ion-color-medium);

  ion-icon {
    font-size: 22px;
  }
}

.synchro-modal {
  --ion-backdrop-opacity: 0.8;
  --height: 300px;
  --border-radius: 10px;

  .modal-wrapper {
    margin: 0 50px;
    height: 200px;
  }
}

.search-modal {
  --ion-backdrop-opacity: 0.8;
}

.pdv-modal {
  --background: rgba(255, 255, 255, 0.8) !important;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  --border-radius: 20px;
  --width: 80%;
  --max-width: 400px;
  --height: 60%;
  --max-height: 360px;
}

.sos-modal {
  --ion-backdrop-opacity: 0.8;
  --height: 300px;
  --border-radius: 10px;
}

ion-modal {
  overflow: none;
  --border-width: 0;
}

.m-0 {
  margin: 0;
}

/* ***********************************************************
* Entête
*/

ion-title {
  font-weight: bold;
  font-size: 16px;
  color: var(--ion-color-tertiary);

  @include media-breakpoint-up(lg) {
    font-size: 26px;
  }
}

ion-header.modal {
  background: #ffffff !important;
}

ion-toolbar.modal {
  --background: #ffffff !important;
  --color: #000000 !important;
}

ion-tab-bar {
  --background: #39343f;
  height: 65px;
  -webkit-box-shadow: 0px 0px 10px 4px rgba(57, 51, 63, 0.16);
  box-shadow: 0px 0px 10px 4px rgba(57, 51, 63, 0.16);
  color: #ffffff;

  .tab-selected {
    color: var(--info);

    ion-icon {
      color: #e5236a;
    }

    ion-label {
      color: #e5236a;
    }
  }

  ion-icon {
    color: #ffffff;
  }

  ion-label {
    color: #ffffff;
  }
}

ion-tab-button {
  ion-icon {
    font-size: 18px;
  }

  ion-label {
    font-size: 11px;
  }
}

ion-button {
  --color: white;
  --border-radius: 5px;
  border-radius: 5px;
  overflow: hidden;
}

/* ***********************************************************
* card
*/
ion-card {
  background-color: #f7f8fa;
  box-shadow: none;
  margin: 2px 2px;
}

ion-card-title {
  font-weight: bold;
  font-size: 16px;
}

.uppercase {
  text-transform: uppercase !important;
}

.card-forward-icon {
  position: absolute;
  top: 50%;
  right: 35px;
  transform: translateY(-50%);
  color: rgba(0, 0, 0, 0.3);
}

.btn-disabled {
  --background: grey !important;
}

.float-button {
  width: 57px;

  ion-text {
    width: 0;
    display: block;
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: 0.2s visibility linear;
  }

  ion-icon {
    color: white;
  }

  &.button-round {
    --border-radius: 64px;
    --padding-top: 12px;
    --padding-start: 12px;
    --padding-end: 12px;
    --padding-bottom: 12px;
  }

  &.show-float {
    width: 150px;
    border-radius: 20px;

    ion-text {
      width: auto;
      height: auto;
      visibility: visible;
      opacity: 1;
      margin-left: 10px;
      color: white;
      display: inline-block;
      transition: 0.2s visibility linear;
    }
  }
}

.round-btn {
  --border-radius: 64px;
  --padding-top: 6px;
  --padding-start: 6px;
  --padding-end: 6px;
  --padding-bottom: 6px;
  width: 27px;

  ion-icon {
    margin: 0 auto;
    color: white;
  }

  ion-header & {
    padding: 0;
    width: auto;
  }
}

// Utilities

.mb-lg {
  margin-bottom: 1.5rem;
}

.mb-md {
  margin-bottom: 1rem;
}

.mt-lg {
  margin-top: 1.5rem;
}

.mt-md {
  margin-top: 1rem;
}

.h-100 {
  height: 100%;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.alert-radio-label,
.alert-checkbox-label {
  white-space: normal !important;
}

.cliquable {
  cursor: pointer;
}

.close {
  float: right;
  font-size: 25px;
  cursor: pointer;
}

.hotline {
  font-size: small;
  text-align: center;
}

.d-flex {
  display: flex !important;
}

.container {
  display: flex;
  align-items: center;
}

.mr {
  &-0 {
    margin-right: 0;
  }
}

.w-100 {
  width: 100%;
}

.text {
  &-left {
    text-align: left;
  }

  &-right {
    text-align: right;
  }
}

.vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}

.p {
  &-0 {
    padding: 0;
  }

  &b-0 {
    padding-bottom: 0;
  }

  &t-0 {
    padding-top: 0;
  }

  &y-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.card-list {
  -webkit-box-shadow: 0px 0 5px 0px rgba(0, 0, 0, 10%);
  -moz-box-shadow: 0px 0 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 10%);
  background: #f7f8fa;
}

//typography

h1 {
  font-size: 18px;
  font-weight: 700;
  opacity: 0.7;

  @include media-breakpoint-up(md) {
    font-size: 24px;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.small {
  font-size: 8pt !important;
}

ion-img.sync {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  max-width: 120px;
}

ion-badge.sync {
  margin-right: 6vw;
}

.w100 {
  width: 100%;
}

.alert-radio-label {
  height: 50px !important;
}

ion-header {
  ion-toolbar {
    --background: #e5236a !important;
  }
}

ion-button.medium {
  min-width: 95px;
}

ion-button.custom {
  --background: #e5236a !important;
  --background-activated: #ffffff !important;
  color: #ffffff !important;
}

ion-button.custom:active {
  color: #e5236a !important;
}

ion-button.blue {
  --background: #0f80de !important;

  ion-icon {
    color: #ffffff !important;
  }
}

ion-button.sos {
  --background: #46cca0 !important;
  height: 26px;

  ion-icon {
    color: #ffffff !important;
  }
}

ion-button.green {
  --background: #46cca0 !important;
  width: 50px;
  height: 50px;

  ion-icon {
    color: #ffffff !important;
  }
}

ion-button.yellow {
  --background: #e8be22;
  width: 50px;
  height: 50px;

  ion-icon {
    color: #ffffff !important;
  }
}

ion-badge.green {
  --background: #46cca0;
  --color: #ffffff;
}

ion-badge.yellow {
  --background: #e8be22;
  --color: #ffffff;
}

ion-badge.gray {
  --background: #778080;
  --color: #ffffff;
}

ion-modal.calendar {
  --height: 460px !important;
  --width: 320px !important;
  --border-radius: 25px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal.statuttel {
  --height: 78% !important;
  --width: 90% !important;
  --border-radius: 25px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal.select {
  --height: 80vh !important;
  --width: 95vw;
  --max-width: 400px !important;
  --margin: 0 15px;
  --border-radius: 30px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-button.calendar {
  --color: #e5236a !important;
}

ion-back-button {
  color: #ffffff;
}

ion-footer {
  --background: transparent !important;
}

/* This part is for the place holder when the select is not open */
ion-select::part(placeholder),
ion-select::part(text) {
  white-space: normal !important;
}

/* This part is for option text when you open the select */
.select-interface-option span {
  white-space: normal !important;
}

.alert-radio-label.sc-ion-alert-md {
  height: auto !important;
}

.alert-radio-label.sc-ion-alert-ios {
  height: auto !important;
}

.alert-tappable.sc-ion-alert-ios {
  height: 54px !important;
}

button.alert-button.alert-button-role-cancel {
  color: black !important;
}

button.alert-button.alert-button {
  color: #e5236a;
}

.footer {
  position: absolute;
  bottom: 10px;
  font-size: 9pt;
  left: 50%;
  width: 100%;

  ion-col {
    position: relative;
    left: -50%;
  }
}

ion-label.custom {
  color: #e5236a !important;
}

.custom-action-sheet .action-sheet-button {
  color: #e5236a !important;
}

.close-icon {
  position: absolute;
  right: 5px;
  font-size: 24px;
  z-index: 1000;
  cursor: pointer;
}

.btn-add {
  --background: transparent;
  --color: #39343f;
  color: #39343f;
  --border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #e5236a;

  --background-activated: transparent;
  --background-focused: transparent;
  --background-hover: transparent;
  display: inline-block;

  ion-icon {
    color: #e5236a;
  }
}

ion-item.shadow {
  margin: 8px 0;
  box-shadow:
    0 2px 4px 0 rgba(0, 0, 0, 0.3),
    0 4px 12px 0 rgba(0, 0, 0, 0.29);
}
